import { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import CsvDownloadButton from "react-json-to-csv";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
} from "@mui/material";

import {
  TextField,
  TextareaAutosize,
  Switch,
  FormControlLabel,
  Fab,
  Typography,
  Stack,
  Paper,
  Input,
  FormHelperText,
  Modal,
  Grid,
  Snackbar,
  Alert
} from "@mui/material";
// addition
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import DownloadIcon from "@mui/icons-material/Download";
import CreateIcon from "@mui/icons-material/Create";

import { useTheme } from "@mui/material/styles";
import { QRCode } from "react-qrcode-logo";

import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { ThumbUp, Favorite, ShoppingCart } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import structures from "../../../data_formats";
import axios from "axios";
import { connect } from "react-redux";
import { mapStateToProps, mapDispachToProps } from "../../../store/MapToProps";
import Loder from "../../common/loder";

import { addPRFieldProvider } from "../../../store/actions";

import moment from "moment";
import "./index.css";
import $ from "jquery";
import { postApiCall, postApiCallFormData } from "../../common/Network";
import { useDispatch } from "react-redux";
const URL1 = process.env.REACT_APP_API_URL;

let values = {};
let fields = [];

const checkField = (field = "", state = {}) => {
  if (["paypalClientId", "paypalClientSecret"].includes(field)) {
    if (state.paypalMode) {
      return "";
    } else {
      return "none";
    }
  } else {
    return "";
  }
};

const GetField = ({ structure, id, data }) => {
  const [data2, setData] = useState([]);
  const [data4, setData4] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [PRFeedData, setPRFeedData] = useState([]);
  const [filename, setFilename] = useState({});
  const [item, setItem] = useState({});
  const [multipleImage, setMultipleImage] = useState([]);
  const [multiplePRText, setMultiplePRText] = useState([""]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [providerId, setProviderId] = useState("");
  const [bonuskarteModal, setBonuskarteModal] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [month, setMonth] = useState("");
  // const toggleModal = () => setVisible(true);
  const theme = useTheme();
  const qrCodeRefs = useRef({});

  const [maxPoints, setMaxPoints] = useState("");
  const [validUntil, setValidUntil] = useState("");
  const [cardDesc, setCardDesc] = useState("");
  const [isEnabled, setIsEnabled] = useState(true); //done
  const [visible, setVisible] = useState(false);
  const [updateQrCodes, setUpdateQrCodes] = useState(false);
  const [cardsData, setCardsData] = useState([]);
  const [availibilityFound, setAvailibilityFound]= useState(false)
  const [showWeeklyScheduleCard, setShowWeeklyScheduleCard]= useState(false);
  const [editCardId, setEditCardId] = useState("");
  const [cardEditModel, setCardEditModel] = useState(false);

  const [activeDays, setActiveDays] = useState({});

  const [dayTimes, setDayTimes] = useState({});
  const [avaliability_loading, setAvaliabilityLoading] = useState(true)
  const [notification, setNotification] = useState(null);

  let editModalFields = {
    maxPoints: "",
    validUntil: "",
    cardDesc: "",
    status: "",
  };

  const handleChange = (event) => {
    setMonth(event.target.value);
    generateReportDataForTheProvider(event.target.value);
  };

  // qcode
  const toggleModal = () => {
    setVisible(!visible);
  };
  const [qcodes, setQcodes] = useState([]);

  const addQcode = () => {
    // Add a new Qcode object with initial values
    setQcodes([...qcodes, { status: "active", points: 5, expiryDate: "" }]);
  };

  const handleInputChange = (index, event, field) => {
    const updatedQcodes = [...qcodes];
    if (field == "expiryDate") {
      updatedQcodes[index][field] = moment(event.target.value).unix();
      setQcodes(updatedQcodes);
      return;
    }
    updatedQcodes[index][field] = event.target.value;
    setQcodes(updatedQcodes);
  };

  //
  let fetchUpdateDataForUpdateModal = (cardId) => {
    setFetching(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/cards/getCardById/${cardId}`)
      .then((response) => {
        let data = response.data.result.data[0];
        console.log(data);
        data.status == "enabled" ? setIsEnabled(true) : setIsEnabled(false);
        setMaxPoints(data.maxPoints);
        setCardDesc(data.details);

        setValidUntil(moment.unix(data.validUntil).format("YYYY-MM-DD"));
        setFetching(false);
        setCardEditModel(!cardEditModel);
        // fetchCardsForVendor();
      })
      .catch((error) => {
        console.log("Error fetching card Details: ", error);
      });
  };
  
  let deleteProvider = (pId)=>{
    setFetching(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/providers/deleteOneUser`, {
        month,
        id: providerId,
      }) // providerId).
      .then((response) => {

        setFetching(false);
      })
      .catch((error) => {
        console.log("Error fetching report data ", error);
      });
  }

  let deleteLoyaltyCard= (cardId)=>{
    setFetching(true);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/cards/deleteCardById/${cardId}`)
      .then((response) => {
        setFetching(false);
        fetchCardsForVendor();
      })
      .catch((error) => {
        console.log("Error fetching card Details: ", error);
      });
  }

  let generateReportDataForTheProvider = (month) => {
    setFetching(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/cards/getCardRedemptionReports`, {
        month,
        id: providerId,
      }) // providerId).
      .then((response) => {
        console.log(response.data.data);
        setReportData(response.data.data);
        setFetching(false);
      })
      .catch((error) => {
        console.log("Error fetching report data ", error);
      });
  };

  const updateCardById = (cardId) => {
    setFetching(true);
    axios
      .put(`${process.env.REACT_APP_API_URL}/cards/updateCardById`, {
        _id: cardId,
        maxPoints: maxPoints,
        status: isEnabled ? "enabled" : "disabled",
        details: cardDesc,
        validUntil: moment(validUntil).unix(),
      })
      .then((response) => {
        console.log("update success: ", response);
        setCardEditModel(!cardEditModel);
        fetchCardsForVendor();
        setFetching(false);
      })
      .catch((error) => {
        console.log("Error fetching card Details: ", error);
      });
  };

  const handleEnableChange = () => {
    setIsEnabled(true);
    console.log("status changed:", isEnabled);
  };

  const handleDisableChange = () => {
    setIsEnabled(false);
    console.log("status changed:", isEnabled);
  };
  const dispatch = useDispatch();

  let currentDate = moment(new Date()).format("YYYY-MM-DD");
  //   let previous=new Date()
  //   previous.setDate(previous.getDate()-1)
  //  let previousDate= moment(previous).format('YYYY-MM-DD')

  const handleSubmitAvailibitityAddition = async () => {
    const apiUrl = `${URL1}/providers_test/create-availibility`;

    const data = Object.keys(activeDays).map(day => {
      if (activeDays[day]) {
        return {
          from: dayTimes[day]?.start || '',
          to: dayTimes[day]?.end || '',
          day: day.toLowerCase(),
          providerId: providerId
        };
      }
      return null;
    }).filter(item => item !== null);

    try {
      await axios.post(apiUrl, data);
      setNotification({ message: 'Availibility added successfully!', severity: 'success' });
    } catch (error) {
      setNotification({ message: 'Failed to added data. Please try again.', severity: 'error' });
    }
  };

  const handleUpdateAvailibitity= async ()=>{
    
    const data = Object.keys(activeDays).map(day => {
      if (activeDays[day]) {
        return {
          from: dayTimes[day]?.start || '',
          to: dayTimes[day]?.end || '',
          day: day.toLowerCase(),
          providerId: providerId
        };
      }
      return null;
    }).filter(item => item !== null);

    const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      data.providerId = data.id;;

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: "follow"
      };
    try {
      const apiUrl = `${URL1}/providers_test/update-availability`;
      await fetch(apiUrl, requestOptions);
      
      onWeeklyScheduleButtonClick()
      
      await axios.post(apiUrl, data);
      setNotification({ message: 'Availibility updated successfully!', severity: 'success' });
    } catch (error) {
      console.log(error,"ERRRROOR")
    }
  }

  useEffect(() => {
    //window.URL.createObjectURL = function() {};
    if (data) {
      setFilename({});
      setProviderId(data.id);
      if (data.PRFeedData) {
        //setPRFeedData(data.PRFeedData)

        let dataArr = [];
        data.PRFeedData.map((item) => {
          let temp = {
            ...item,
            picture_1Url: URL1 + item.picture_1.url,
            picture_2Url: URL1 + item.picture_2.url,
          };
          dataArr.push(temp);
        });
        setPRFeedData(dataArr);
      }
      setItem({ ...item, ...data });
      values = {
        ...values,
        id: data.id,
      };
    }
  }, [data]);


  const onWeeklyScheduleButtonClick = ()=>{
    if (providerId != "" || providerId != null) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "providerId": data.id
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      fetch(`${URL1}/providers_test/check-availibility`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          
          let days_ac = result.result.map((item) => item.day)
          
          
          const daysObject = days_ac.reduce((obj, day) => {
            const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);
            obj[capitalizedDay] = true;
            return obj;
          }, {});
          
          let isAvailibilityFound= Object.values(daysObject).some(day => day === true);
          
          if(isAvailibilityFound){
            setAvailibilityFound(true)
          }
          
          setActiveDays(daysObject)
          
          const availabilityObject = result.result.reduce((obj, item) => {
            const capitalizedDay = item.day.charAt(0).toUpperCase() + item.day.slice(1);
            obj[capitalizedDay] = { start: item.from, end: item.to };
            return obj;
          }, {})
          console.log("days_ac", daysObject)
          setDayTimes(availabilityObject)
          setAvaliabilityLoading(false)
          setShowWeeklyScheduleCard(!showWeeklyScheduleCard)
        })
        .catch((error) => {
          console.error(error)

          setAvaliabilityLoading(false)
        });
    }
  }


  let formattedDate = moment(validUntil).unix();
  const onPressQcode = () => {
    setFetching(true);
    let data = {
      vendorId: providerId,
      status: isEnabled ? "enabled" : "disabled",
      maxPoints: maxPoints,
      validUntil: formattedDate,
      qrCodes: qcodes,
      details: cardDesc,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "/cards/createCard", data)
      .then((response) => {
        setUpdateQrCodes(!updateQrCodes);
        toggleModal();
        fetchCardsForVendor();
        setFetching(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });

    //
  };

  const daysOfWeek = [
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag",
  ];

  const TimeSelector = ({ day, isActive, times, onTimeChange }) => {
    if (!isActive) return null;

    return (
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={6}>
          <TextField
            label="Start Time"
            type="time"
            value={times.start}
            onChange={(e) => {
              console.log("selected time:: ", e.target.value);
              onTimeChange(day, { ...times, start: e.target.value })
            }}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="End Time"
            type="time"
            value={times.end}
            onChange={(e) =>
              onTimeChange(day, { ...times, end: e.target.value })
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    );
  };

  const handleSwitchChange = (day) => {
    setActiveDays((prev) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  const handleTimeChange = (day, newTimes) => {
    setDayTimes((prev) => ({
      ...prev,
      [day]: newTimes,
    }));
  };

  const CardComponent = ({ card }) => {
    const qrCodeRefs = useRef({});

    const downloadQRCode = (qrCodeValue) => {
      const qrCodeRef = qrCodeRefs.current[qrCodeValue];
      if (qrCodeRef) {
        qrCodeRef.download();
      }
    };

    return (
      <div
        className="card"
        style={{
          padding: "5px",
          display: "flex",
          marginLeft: "5px",
          flexDirection: "column",
        }}
      >
        <div
          className="card-header"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p className="">Status: {card.status}</p>
          <div>
          <DeleteIcon
            style={{ marginRight: "5px" }}
            onClick={() => {
              deleteLoyaltyCard(card._id);
            }}
          />
          <CreateIcon
            style={{ marginRight: "5px" }}
            onClick={() => {
              setEditCardId(card._id);
              fetchUpdateDataForUpdateModal(card._id);
              console.log(card._id);

            }}
          />
          </div>
        </div>
        <p>Max Points: {card.maxPoints}</p>
        <p>
          Card Description:{" "}
          {card.details ? card.details : "No details provided."}
        </p>
        <div className="card-body">
          <h3>QR Codes:</h3>
          <ul
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {card.qrCodes.map((qrCode, index) => (
              <div
                key={`${index}`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <QRCode
                  ref={(el) => (qrCodeRefs.current[qrCode._id] = el)}
                  id={`qr-code-${qrCode._id}`}
                  size={256}
                  style={{
                    height: "100px",
                    maxWidth: "100%",
                    width: "100px",
                    padding: "5px",
                  }}
                  value={qrCode._id}
                  viewBox={`0 0 256 256`}
                />
                <p>Points: {qrCode.points}</p>
                <span>
                  <label htmlFor="name">Enabled: </label>
                  <input
                    onChange={(e) => {
                      handleCheckboxChange(e, qrCode._id);
                    }}
                    type="checkbox"
                    checked={qrCode.status == "active" ? true : false}
                  />
                </span>
                <IconButton
                  onClick={() => downloadQRCode(qrCode._id)}
                  aria-label="download"
                >
                  <DownloadIcon />
                </IconButton>
              </div>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const handleCheckboxChange = async (event, id) => {
    setFetching(true);
    const checked = event.target.checked;
    console.log("checked", checked);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/cards/updateQrCode`,
        {
          _id: id,
          status: checked ? "active" : "inactive",
        }
      );

      console.log("API response:", response.data);
      fetchCardsForVendor();
      setFetching(false);
    } catch (error) {
      console.error("Error making API request:", error);
    }
  };

  const CardsList = () => {
    return (
      <div>
        {cardsData &&
          cardsData.length > 0 &&
          cardsData.map((card) => <CardComponent key={card._id} card={card} />)}
      </div>
    );
  };
  useEffect(() => {
    setFetching(false);
    if (data2.length <= 0 && structure.inputType == "select") {
      axios
        .get(
          process.env.REACT_APP_API_URL + "/getcategory?type=" + structure.data
        )
        .then((res) => {
          setData(res.data.result);
          setFetching(true);
        });
    }
    if (data4.length <= 0 && structure.inputType == "select2") {
      axios
        .get(
          process.env.REACT_APP_API_URL + "/getcategory?type=" + structure.data4
        )
        .then((res) => {
          setData4(res.data.result);
        });
    }
  }, []);

  const addFetchFunc = async () => {
    if (modalData.subject_line == "" || modalData.input_text == "") {
      alert(`Please provide all the field`);
      return;
    } else if (modalData.post_start_date >= modalData.post_end_date) {
      alert("Please Provide valid Date range");
      return;
    } else {
      // let key = structure.field
      // var temp={

      //   subject_line:modalData.subject_line,
      //   feed_show_status:modalData.feed_show_status,
      //   input_text:modalData.input_text,
      //   picture_1:modalData.picture_1,
      //   picture_2:modalData.picture_2,
      //    }
      //const tempArr=[...PRFeedData,modalData]

      let data = new FormData();
      data.append("providerId", providerId);
      data.append("subject_line", modalData.subject_line);
      data.append("feed_show_status", modalData.feed_show_status);
      data.append("input_text", modalData.input_text);
      data.append("picture_1", modalData.picture_1);
      data.append("picture_2", modalData.picture_2);
      data.append("post_start_date", modalData.post_start_date);
      data.append("post_end_date", modalData.post_end_date);

      setShowModal(false);
      dispatch({ type: "ENABLE_LODER" });
      await postApiCallFormData("/providers/addPRFeedData", data, {})
        .then((res) => {
          dispatch({ type: "DISABLE_LODER" });
          alert(res.data.msg);
          if (res.data.status === true) {
            //setPRFeedData(res.data.data.PRFeedData)
            let dataArr = [];
            res.data.data.PRFeedData.map((item) => {
              let temp = {
                ...item,
                picture_1Url: URL1 + item.picture_1.url,
                picture_2Url: URL1 + item.picture_2.url,
              };
              dataArr.push(temp);
            });
            setPRFeedData(dataArr);
          }
        })
        .catch((e) => {
          dispatch({ type: "DISABLE_LODER" });
        });
    }
  };

  const cancelPRFeedPostFunc = (item) => {
    setShowModal(false);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        cancelPRFeedPostFunc1(item);
      }
    });
  };

  const cancelPRFeedPostFunc1 = async (item) => {
    const sendData = {
      providerId: providerId,
      PRFeedId: item._id,
    };
    dispatch({ type: "ENABLE_LODER" });
    await postApiCall("/providers/deletePRFeedData", sendData, {})
      .then((res) => {
        dispatch({ type: "DISABLE_LODER" });
        alert(res.data.msg);
        if (res.data.status === true) {
          var tempArr = PRFeedData.filter((data, i) => data._id != item._id);
          setPRFeedData(tempArr);
        }
      })
      .catch((e) => {
        dispatch({ type: "DISABLE_LODER" });
      });
  };

  const editFetchFunc = async () => {
    if (
      modalData.subject_line == "" ||
      modalData.input_text == "" ||
      modalData.picture_1Url == "" ||
      modalData.picture_2Url == ""
    ) {
      alert(`Please provide all the field`);
      return;
    } else if (modalData.post_start_date >= modalData.post_end_date) {
      alert("Please Provide valid Date range");
      return;
    } else {
      let data = new FormData();
      data.append("providerId", providerId);
      data.append("PRFeedId", modalData._id);
      data.append("subject_line", modalData.subject_line);
      data.append("feed_show_status", modalData.feed_show_status);
      data.append("input_text", modalData.input_text);
      data.append("picture_1", modalData.picture_1);
      data.append("picture_2", modalData.picture_2);
      data.append("post_start_date", modalData.post_start_date);
      data.append("post_end_date", modalData.post_end_date);

      setShowModal(false);
      dispatch({ type: "ENABLE_LODER" });
      await postApiCallFormData("/providers/editPRFeedData", data, {})
        .then((res) => {
          dispatch({ type: "DISABLE_LODER" });
          alert(res.data.msg);
          if (res.data.status === true) {
            //setPRFeedData(res.data.data.PRFeedData)
            let dataArr = [];
            res.data.data.PRFeedData.map((item) => {
              let temp = {
                ...item,
                picture_1Url: URL1 + item.picture_1.url,
                picture_2Url: URL1 + item.picture_2.url,
              };
              dataArr.push(temp);
            });
            setPRFeedData(dataArr);
          }
        })
        .catch((e) => {
          dispatch({ type: "DISABLE_LODER" });
        });
    }
  };

  const imageFunc = (image) => {
    let img = "";
    if (image.endsWith(".pdf")) {
      img = require("../../../Assets/Images/pdf.png");
    } else {
      img = process.env.REACT_APP_WEB_URL + item[structure.field];
    }
    return img;
  };

  const fetchCardsForVendor = () => {
    setFetching(true);
    axios
      .get(
        process.env.REACT_APP_API_URL + `/cards/getCardsForVendor/${providerId}`
      )
      .then((response) => {
        setFetching(false);
        setCardsData(response.data.result);
        console.log(cardsData);
      });
  };
  switch (structure.inputType) {
    case "text":
      return (
        <TextField
          className={`${id}_${structure.field}`}
          id={`${id}_${structure.field}`}
          variant="outlined"
          type={structure.inputType}
          label={structure.headerName}
          sx={{
            marginBottom: "2em",
            width: "30em",
            display: checkField(structure.field, item),
          }}
          value={item[structure.field] || ""}
          onChange={(e) => {
            let key = structure.field;
            if (key == "minOrderCost" || key == "deliveryCost") {
              setItem({
                ...item,
                [key]: e.currentTarget.value.replace(/,/g, "."),
              });
            } else {
              setItem({ ...item, [key]: e.currentTarget.value });
            }
            // setItem({ ...item, [key]: e.currentTarget.value })
            values = { ...values, [key]: e.currentTarget.value };
            // values.append(key, e.currentTarget.value)
          }}
          disabled={structure.isDisabled ? true : false}
        />
      );
    case "multipleText":
      return (
        <>
          {multiplePRText.map((item, index) => {
            return (
              <Stack direction="row" spacing={2} key={index}>
                <TextField
                  className={`${id}_${structure.field}`}
                  id={`${id}_${structure.field}`}
                  name={`${id}_${structure.field}`}
                  type={structure.inputType}
                  label={structure.headerName}
                  autoComplete="false"
                  variant="outlined"
                  sx={{
                    marginBottom: "2em",
                    width: "30em",
                    display: structure.hideInput
                      ? "none"
                      : checkField(structure.field, item),
                  }}
                  value={multiplePRText[index]}
                  onChange={(e) => {
                    let key = structure.field;
                    const temp = [...multiplePRText];
                    temp[index] = e.currentTarget.value;
                    setMultiplePRText(temp);
                    setItem({ ...item, [key]: multiplePRText });
                    values = { ...values, [key]: multiplePRText };
                  }}
                  disabled={structure.isDisabled ? true : false}
                />
                <Paper elevation={3} sx={{ position: "relative" }}>
                  <CancelIcon
                    onClick={(e) => {
                      let key = structure.field;
                      var tempArr = multiplePRText.filter(
                        (item, i) => i != index
                      );

                      setMultiplePRText(tempArr);
                      setItem({ ...item, [key]: tempArr });
                      values = { ...values, [key]: tempArr };
                    }}
                    sx={{
                      right: "-20px",
                      // top: "-8px",
                      position: "absolute",
                    }}
                  />
                </Paper>
              </Stack>
            );
          })}
          {multiplePRText.length < 10 && (
            <Fab
              style={{ marginBottom: "20px" }}
              color="secondary"
              component="span"
              aria-label="add"
              variant="extended"
              onClick={() => {
                if (multiplePRText.length == 10) {
                  alert("Maximum of 10 Text only");
                } else {
                  setMultiplePRText([...multiplePRText, ""]);
                }
              }}
            >
              <AddIcon />
              {structure.headerName}
            </Fab>
          )}
        </>
      );
    case "multiText":
      return (
        <>
          {/* <h3>{structure.headerName}</h3> */}
          <TextareaAutosize
            className={`${id}_${structure.field}`}
            id={`${id}_${structure.field}`}
            name={`${id}_${structure.field}`}
            type={structure.inputType}
            label={structure.headerName}
            placeholder={structure.headerName}
            autoComplete="false"
            maxRows={10}
            minRows={8}
            variant="outlined"
            aria-label="maximum height"
            style={{
              marginBottom: "2em",
              width: "27em",
              fontSize: "18px",
              padding: "0.5em",
              display: checkField(structure.field, item),
            }}
            value={item[structure.field] || ""}
            onChange={(e) => {
              let key = structure.field;
              setItem({ ...item, [key]: e.currentTarget.value });
              values = { ...values, [key]: e.currentTarget.value };
            }}
            disabled={structure.isDisabled ? true : false}
          />
        </>
      );
    case "select":
      return (
        <FormControl style={{ width: "45.5%", marginBottom: 10 }}>
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            value={item[structure.field] || ""}
            onChange={(e) => {
              let key = structure.field;
              setItem({ ...item, [key]: e.target.value });
              values = { ...values, [key]: e.target.value };
            }}
            className={`${id}_${structure.field}`}
            id={`${id}_${structure.field}`}
            name={`${id}_${structure.field}`}
          >
            {data2.map((ans, i) => (
              <MenuItem key={i + 1} value={ans.Name}>
                {ans.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "select2":
      return (
        <FormControl style={{ width: "45.5%", marginBottom: 10 }}>
          <InputLabel id="demo-simple-select-label">Community</InputLabel>
          <Select
            value={item[structure.field] || ""}
            onChange={(e) => {
              let key = structure.field;
              setItem({ ...item, [key]: e.target.value });
              values = { ...values, [key]: e.target.value };
            }}
            className={`${id}_${structure.field}`}
            id={`${id}_${structure.field}`}
            name={`${id}_${structure.field}`}
          >
            {data4.map((ans, i) => (
              <MenuItem key={i + 1} value={ans.Name}>
                {ans.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "date":
      return (
        <TextField
          className={`${id}_${structure.field}`}
          id={`${id}_${structure.field}`}
          name={`${id}_${structure.field}`}
          type={structure.inputType}
          label={structure.headerName}
          autoComplete="false"
          variant="outlined"
          inputProps={{ min: item[structure.minimumDate] || currentDate }}
          sx={{
            marginBottom: "2em",
            width: "30em",
            display: structure.hideInput ? "none" : "",
          }}
          value={item[structure.field] || currentDate}
          onSelect={(e) => {
            let key = structure.field;
            setItem({ ...item, [key]: e.target.value });
            values = { ...values, [key]: e.target.value };
          }}
          onChange={(e) => {
            let key = structure.field;
            setItem({ ...item, [key]: e.currentTarget.value });
            values = { ...values, [key]: e.currentTarget.value };
          }}
          disabled={structure.isDisabled ? true : false}
        />
      );
    case "file":
      return (
        <div
          className={`${id}_${structure.field}`}
          style={{ marginBottom: "2em" }}
        >
          <Stack direction="row" spacing={2}>
            <label htmlFor={`${id}_${structure.field}`}>
              <input
                style={{ display: "none" }}
                name={`${id}_${structure.field}`}
                type="file"
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                onChange={(e) => {
                  let size = e.target.files[0].size;
                  let file = document.getElementById(e.currentTarget.id);
                  let nameArr = ((file.files[0] || {}).name || "").split(".");
                  let ext = nameArr[nameArr.length - 1];

                  if (!(structure.fileType || []).includes(ext)) {
                    alert(
                      "Please Upload these files -> " +
                      structure.fileType.join(", ")
                    );
                    return false;
                  } else {
                    if (size > 36700165) {
                      alert("Please Upload files upto 35 mb.");
                      return false;
                    }
                  }

                  let key = structure.field;
                  values = { ...values, [key]: file.files[0] };
                  if (ext == "pdf") {
                    var tmppath = require("../../../Assets/Images/pdf.png");
                  } else {
                    var tmppath = URL.createObjectURL(e.target.files[0]);
                  }
                  //var tmppath = URL.createObjectURL(e.target.files[0]);
                  // values.append(key, file.files[0])
                  setFilename({
                    ...filename,
                    [key]: file.files[0].name,
                    [key + "_preview"]: tmppath,
                  });
                }}
              />

              <Fab
                color="secondary"
                component="span"
                aria-label="add"
                variant="extended"
              >
                <AddIcon /> {structure.headerName}
              </Fab>
            </label>
            {item[structure.field] || filename[structure.field + "_preview"] ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    height: 120,
                    width: 120,
                  },
                }}
              >
                <Paper elevation={3} sx={{ position: "relative" }}>
                  <CancelIcon
                    onClick={(e) => {
                      // alert("clicked")
                      setFilename({
                        ...filename,
                        [structure.field + "_preview"]: null,
                      });
                      setItem({
                        ...item,
                        [structure.field]: null,
                      });
                      values[structure.field] = {};
                    }}
                    sx={{
                      right: "-20px",
                      top: "-18px",
                      position: "absolute",
                    }}
                  />
                  {filename[structure.field + "_preview"] !== null ||
                    item[structure.field] !== null ? (
                    <img
                      style={{ borderRadius: "2px" }}
                      height="120"
                      width="120"
                      //src={filename[structure.field + "_preview"] || process.env.REACT_APP_WEB_URL + item[structure.field]}
                      src={
                        filename[structure.field + "_preview"] ||
                        imageFunc(item[structure.field])
                      }
                      alt={require("../../../Assets/Images/pdf.png")}
                    />
                  ) : (
                    <></>
                  )}
                </Paper>
              </Box>
            ) : (
              <></>
            )}
            {filename[structure.field + "_preview"] !== null ||
              item[structure.field] !== null ? (
              <Typography sx={{ margin: "auto 1em !important" }}>
                {filename[structure.field]}
              </Typography>
            ) : (
              <></>
            )}
          </Stack>
        </div>
      );

    case "videoFile":
      return (
        <div
          className={`${id}_${structure.field}`}
          style={{ marginBottom: "2em" }}
        >
          <Stack direction="row" spacing={2}>
            <label htmlFor={`${id}_${structure.field}`}>
              <input
                style={{ display: "none" }}
                name={`${id}_${structure.field}`}
                type="file"
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                onChange={(e) => {
                  let size = e.target.files[0].size;
                  let file = document.getElementById(e.currentTarget.id);
                  let nameArr = ((file.files[0] || {}).name || "").split(".");
                  let ext = nameArr[nameArr.length - 1];

                  if (!(structure.fileType || []).includes(ext)) {
                    alert(
                      "Please Upload these files -> " +
                      structure.fileType.join(", ")
                    );
                    return false;
                  } else {
                    if (size > 36700165) {
                      alert("Please Upload files upto 35 mb.");
                      return false;
                    }
                  }

                  let key = structure.field;
                  values = { ...values, [key]: file.files[0] };
                  var tmppath = URL.createObjectURL(e.target.files[0]);

                  setFilename({
                    ...filename,
                    [key]: file.files[0].name,
                    [key + "_preview"]: tmppath,
                  });
                }}
              />

              <Fab
                color="secondary"
                component="span"
                aria-label="add"
                variant="extended"
              >
                <AddIcon /> {structure.headerName}
              </Fab>
            </label>
            {item[structure.field] || filename[structure.field + "_preview"] ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    height: 100,
                    width: 120,
                  },
                }}
              >
                <Paper elevation={3} sx={{ position: "relative" }}>
                  <CancelIcon
                    onClick={(e) => {
                      // alert("clicked")
                      setFilename({
                        ...filename,
                        [structure.field + "_preview"]: null,
                      });
                      setItem({
                        ...item,
                        [structure.field]: null,
                      });
                      values[structure.field] = {};
                    }}
                    sx={{
                      right: "-20px",
                      top: "-18px",
                      position: "absolute",
                    }}
                  />
                  {filename[structure.field + "_preview"] !== null ||
                    item[structure.field] !== null ? (
                    <img
                      style={{ borderRadius: "2px" }}
                      height="100"
                      width="120"
                      src={require("../../../Assets/Images/video.png")}
                      alt={require("../../../Assets/Images/video.png")}
                    />
                  ) : (
                    <></>
                  )}
                </Paper>
              </Box>
            ) : (
              <></>
            )}
            {filename[structure.field + "_preview"] !== null ||
              item[structure.field] !== null ? (
              <Typography sx={{ margin: "auto 1em !important" }}>
                {filename[structure.field]}
              </Typography>
            ) : (
              <></>
            )}
          </Stack>
        </div>
      );

    case "pdfFile":
      return (
        <div
          className={`${id}_${structure.field}`}
          style={{ marginBottom: "2em" }}
        >
          <Stack direction="row" spacing={2}>
            <label htmlFor={`${id}_${structure.field}`}>
              <input
                style={{ display: "none" }}
                name={`${id}_${structure.field}`}
                type="file"
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                onChange={(e) => {
                  let size = e.target.files[0].size;
                  let file = document.getElementById(e.currentTarget.id);
                  let nameArr = ((file.files[0] || {}).name || "").split(".");
                  let ext = nameArr[nameArr.length - 1];

                  if (!(structure.fileType || []).includes(ext)) {
                    alert(
                      "Please Upload these files -> " +
                      structure.fileType.join(", ")
                    );
                    return false;
                  } else if (ext !== "pdf") {
                    alert("Please Upload pdf only.");
                    return false;
                  }

                  let key = structure.field;
                  values = { ...values, [key]: file.files[0] };
                  var tmppath = URL.createObjectURL(e.target.files[0]);
                  // values.append(key, file.files[0])
                  setFilename({
                    ...filename,
                    [key]: file.files[0].name,
                    [key + "_preview"]: tmppath,
                  });
                }}
              />

              <Fab
                color="secondary"
                component="span"
                aria-label="add"
                variant="extended"
              >
                <AddIcon /> {structure.headerName}
              </Fab>
            </label>
            {item[structure.field] || filename[structure.field + "_preview"] ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    height: 120,
                    width: 120,
                  },
                }}
              >
                <Paper elevation={3} sx={{ position: "relative" }}>
                  <CancelIcon
                    onClick={(e) => {
                      // alert("clicked")
                      setFilename({
                        ...filename,
                        [structure.field + "_preview"]: null,
                      });
                      setItem({
                        ...item,
                        [structure.field]: null,
                      });
                      values[structure.field] = {};
                    }}
                    sx={{
                      right: "-20px",
                      top: "-18px",
                      position: "absolute",
                    }}
                  />
                  {filename[structure.field + "_preview"] !== null ||
                    item[structure.field] !== null ? (
                    <img
                      style={{ borderRadius: "2px" }}
                      height="120"
                      width="120"
                      src={require("../../../Assets/Images/pdf.png")}
                    />
                  ) : (
                    <></>
                  )}
                </Paper>
              </Box>
            ) : (
              <></>
            )}
            {filename[structure.field + "_preview"] !== null ||
              item[structure.field] !== null ? (
              <Typography sx={{ margin: "auto 1em !important" }}>
                {filename[structure.field]}
              </Typography>
            ) : (
              <></>
            )}
          </Stack>
        </div>
      );

    case "multiFile":
      return (
        <div
          className={`${id}_${structure.field}`}
          style={{ marginBottom: "2em" }}
        >
          <Stack direction="row" spacing={2}>
            <label htmlFor={`${id}_${structure.field}`}>
              <input
                style={{ display: "none" }}
                name={`${id}_${structure.field}`}
                type="file"
                // multiple={true}
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                onChange={(e) => {
                  let size = e.target.files[0].size;
                  let file = document.getElementById(e.currentTarget.id);
                  let nameArr = ((file.files[0] || {}).name || "").split(".");
                  let ext = nameArr[nameArr.length - 1];

                  if (!(structure.fileType || []).includes(ext)) {
                    alert(
                      "Please Upload these files -> " +
                      structure.fileType.join(", ")
                    );
                    return false;
                  } else if (ext === "jpg" || ext === "jpeg" || ext === "png") {
                    if (size > 10485760) {
                      alert("Please Upload files under 10 mb.");
                      return false;
                    }
                  } else {
                    if (size > 10485760) {
                      alert("Please Upload files under 10 mb.");
                      return false;
                    }
                  }

                  // let key = structure.field
                  //values = { ...values, [key]: file.files[0] }
                  var tmppath = URL.createObjectURL(e.target.files[0]);
                  // let temp=filename[key]
                  // temp.push(file.files[0].name)
                  // let temp1=filename[key+ "_preview"]
                  // temp1.push(tmppath)
                  //setFilename({ ...filename, [key]:temp, [key + "_preview"]: temp1})
                  let tempArr = multipleImage;
                  var temp = {
                    name: file.files[0].name,
                    file: tmppath,
                  };
                  // temp[key]=file.files[0].name,
                  // temp[key+ "_preview"]=tmppath

                  tempArr.push(temp);
                  setMultipleImage([...tempArr]);
                }}
              />

              <Fab
                color="secondary"
                component="span"
                aria-label="add"
                variant="extended"
              >
                <AddIcon /> {structure.headerName}
              </Fab>
            </label>

            {multipleImage.map((item1, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    "& > :not(style)": {
                      height: 120,
                      width: 120,
                    },
                  }}
                >
                  <Paper elevation={3} sx={{ position: "relative" }}>
                    <CancelIcon
                      onClick={(e) => {
                        var tempArr = multipleImage.filter((item2) => {
                          return item2.file != item1.file;
                        });
                        setMultipleImage(tempArr);
                        //values[structure.field] = {}
                      }}
                      sx={{
                        right: "-20px",
                        top: "-18px",
                        position: "absolute",
                      }}
                    />
                    {
                      <img
                        style={{ borderRadius: "2px" }}
                        height="120"
                        width="120"
                        src={item1.file}
                      />
                    }
                  </Paper>
                </Box>
              );
            })}
          </Stack>
        </div>
      );

    case "switch":
      return (
        <FormControlLabel
          control={
            <Switch
              className={`${id}_${structure.field}`}
              id={`${id}_${structure.field}`}
              checked={item[structure.field] ? true : false}
              onChange={(e) => {
                let key = structure.field;
                setItem({ ...item, [key]: e.currentTarget.checked });
                values = { ...values, [key]: e.currentTarget.checked };
                // values.append(key, e.currentTarget.checked)
              }}
            />
          }
          label={structure.headerName}
          disabled={structure.isDisabled ? true : false}
          sx={{
            marginBottom: "2em",
            width: "30em",
          }}
        />
      );

    case "heading":
      return <h3>{structure.title}</h3>;

    case "formText":
      return (
        <FormControl>
          <InputLabel htmlFor="my-input">Email address</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" />
          <FormHelperText id="my-helper-text">
            We'll never share your email.
          </FormHelperText>
        </FormControl>
      );
    case "PRFeed":
      return (
        <>
          {PRFeedData.map((item, index) => {
            return (
              <>
                <Stack direction="row" spacing={2} key={index}>
                  <Button
                    variant="contained"
                    color="info"
                    size="large"
                    sx={{
                      marginBottom: "2em",
                      textTransform: "none",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setModalData({ ...item, index: index });
                      setShowModal(true);
                    }}
                  >
                    <div style={{ marginRight: "20px", alignSelf: "center" }}>
                      {PRFeedData[index].subject_line}
                    </div>
                    <div
                      style={{
                        marginRight: "20px",
                        alignItems: "center",
                        display: "flex",
                        height: "40px",
                        alignSelf: "center",
                        width: "40px",
                        justifyContent: "space-between",
                      }}
                    >
                      <ThumbUp color="error" />
                      <h4>{PRFeedData[index].like_count}</h4>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        height: "40px",
                        alignSelf: "center",
                        width: "40px",
                        justifyContent: "space-between",
                      }}
                    >
                      <ShoppingCart color="error" />
                      <h4>{PRFeedData[index].wishlist_count}</h4>
                    </div>
                  </Button>
                  <Paper elevation={3} sx={{ position: "relative" }}>
                    <CancelIcon
                      onClick={(e) => {
                        cancelPRFeedPostFunc(item, index);
                      }}
                      sx={{
                        right: "-20px",
                        // top: "-8px",
                        position: "absolute",
                      }}
                    />
                  </Paper>
                </Stack>
              </>
            );
          })}
          <Fab
              style={{
                marginBottom: "20px",
                display: "flex",
                width: "200px",
                justifyContent: "center",
                alignItems: "center",
              }}
              color="secondary"
              component="span"
              aria-label="add"
              variant="extended"
              onClick={onWeeklyScheduleButtonClick}
            // onClick={() => setVisible(true)}
            >
              <AddIcon />
              {"Weekly Schedule"}
            </Fab>
          <>
          {
            showWeeklyScheduleCard && (
              <Card style={{ margin: "10px", width: "50%" }}>
              <CardHeader title="Weekly Schedule" />
              {!avaliability_loading && <CardContent>
                {daysOfWeek.map((day) => (
                  <div key={day} style={{ marginBottom: "16px" }}>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={activeDays[day] || false}
                            onChange={() => handleSwitchChange(day)}
                          />
                        }
                        label={<Typography>{day}</Typography>}
                      />
                    </FormControl>
                    <TimeSelector
                      day={day}
                      isActive={activeDays[day] || false}
                      times={dayTimes[day] || { start: "", end: "" }}
                      onTimeChange={handleTimeChange}
                    />
                  </div>
                ))}
              </CardContent> }
              <CardContent>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={availibilityFound ? handleUpdateAvailibitity :handleSubmitAvailibitityAddition}
                >
                  {availibilityFound ? `Update Schedule` : "Submit Schedule" }
                </Button>
              </CardContent>
              {notification && (
                <Snackbar
                  open={Boolean(notification)}
                  autoHideDuration={6000}
                  onClose={() => setNotification(null)}
                >
                  <Alert
                    onClose={() => setNotification(null)}
                    severity={notification.severity}
                  >
                    {notification.message}
                  </Alert>
                </Snackbar>
              )}
            </Card>
            )
          }
            
            <Fab
              style={{
                marginBottom: "20px",
                display: "flex",
                width: "170px",
                justifyContent: "center",
                alignItems: "center",
              }}
              color="secondary"
              component="span"
              aria-label="add"
              variant="extended"
              onClick={toggleModal}
            // onClick={() => setVisible(true)}
            >
              <AddIcon />
              {"Bonuskarte"}
            </Fab>
            
            <Card sx={{ maxWidth: 345 }} style={{ margin: "10px" }}>
              <CardHeader
                title="Generate Cards Report"
                sx={{
                  textAlign: "center",
                  backgroundColor: "#1976d2",
                  color: "white",
                }}
              />
              <CardContent>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                >
                  <InputLabel id="month-select-label">Select Month</InputLabel>
                  <Select
                    labelId="month-select-label"
                    id="month-select"
                    value={month}
                    onChange={handleChange}
                    label="Select Month"
                  >
                    <MenuItem value="1">January</MenuItem>
                    <MenuItem value="2">February</MenuItem>
                    <MenuItem value="3">March</MenuItem>
                    <MenuItem value="4">April</MenuItem>
                    <MenuItem value="5">May</MenuItem>
                    <MenuItem value="6">June</MenuItem>
                    <MenuItem value="7">July</MenuItem>
                    <MenuItem value="8">August</MenuItem>
                    <MenuItem value="9">September</MenuItem>
                    <MenuItem value="10">October</MenuItem>
                    <MenuItem value="11">November</MenuItem>
                    <MenuItem value="12">December</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
              {reportData.length > 0 ? (
                <CardActions sx={{ justifyContent: "center" }}>
                  <CsvDownloadButton data={reportData} />
                </CardActions>
              ) : (
                ""
              )}
            </Card>

            <Fab
              style={{
                marginBottom: "20px",
                display: "flex",
                width: "170px",
                justifyContent: "center",
                alignItems: "center",
              }}
              color="secondary"
              component="span"
              aria-label="add"
              variant="extended"
              onClick={() => {
                fetchCardsForVendor();
              }}
            // onClick={() => setVisible(true)}
            >
              <AddIcon />
              {"Load Cards"}
            </Fab>
            <CardsList />

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isFetching}
              onClick={() => { }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            {/* Edit modal */}
            <div
              className={`fullscreen-modal ${cardEditModel ? "" : "hidden"}`}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Bonus Card</h5>
                  {/* <button type="button" className="btn-close" onClick={() => setVisible(false)}></button> */}
                </div>
                <div className="modal-body">
                  <div style={{ marginBottom: "5px" }}>
                    <label className="input-label">Status</label>
                    <label style={{ marginRight: "16px" }}>
                      <input
                        type="radio"
                        value="enabled"
                        checked={isEnabled}
                        onChange={handleEnableChange}
                        style={{ marginRight: "8px" }}
                      />
                      enabled
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="disabled"
                        checked={!isEnabled}
                        onChange={handleDisableChange}
                        style={{ marginRight: "8px" }}
                      />
                      disabled
                    </label>
                  </div>
                  <div className="modal_iner">
                    <div className="input-container">
                      <label className="input-label">Max Points</label>
                      <input
                        type="number"
                        value={maxPoints}
                        onChange={(e) => setMaxPoints(e.target.value)}
                        className="input"
                      />
                    </div>
                    <div className="input-container">
                      <label className="input-label">Description</label>
                      <textarea
                        onChange={(e) => {
                          setCardDesc(e.target.value);
                          console.log(cardDesc);
                        }}
                        value={cardDesc}
                        className="input"
                      ></textarea>
                    </div>
                    <div className="input-container">
                      <label className="input-label">Valid Until</label>
                      <input
                        type="date"
                        value={validUntil}
                        onChange={(e) => {
                          setValidUntil(e.target.value);
                          console.log(e.target.value);
                        }}
                        className="input"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="footer"
                    onClick={() => setCardEditModel(false)}
                  >
                    Close
                  </button>
                  <button
                    className="footer-button"
                    onClick={() => {
                      updateCardById(editCardId);
                    }}
                  >
                    Save Card
                  </button>
                </div>
              </div>
            </div>
            <div className={`fullscreen-modal ${visible ? "" : "hidden"}`}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Bonuskarte</h5>
                  {/* <button type="button" className="btn-close" onClick={() => setVisible(false)}></button> */}
                </div>
                <div className="modal-body">
                  <div style={{ marginBottom: "5px" }}>
                    <label className="input-label">Status</label>
                    <label style={{ marginRight: "16px" }}>
                      <input
                        type="radio"
                        value="enabled"
                        checked={isEnabled}
                        onChange={handleEnableChange}
                        style={{ marginRight: "8px" }}
                      />
                      enabled
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="disabled"
                        checked={!isEnabled}
                        onChange={handleDisableChange}
                        style={{ marginRight: "8px" }}
                      />
                      disabled
                    </label>
                  </div>
                  <div className="modal_iner">
                    <div className="input-container">
                      <label className="input-label">Max Points</label>
                      <input
                        type="number"
                        value={maxPoints}
                        onChange={(e) => setMaxPoints(e.target.value)}
                        className="input"
                      />
                    </div>
                    <div className="input-container">
                      <label className="input-label">Description</label>
                      <textarea
                        onChange={(e) => {
                          setCardDesc(e.target.value);
                        }}
                        value={cardDesc}
                        className="input"
                      ></textarea>
                    </div>
                    <div className="input-container">
                      <label className="input-label">Valid Until</label>
                      <input
                        type="date"
                        value={validUntil}
                        onChange={(e) => setValidUntil(e.target.value)}
                        className="input"
                      />
                    </div>
                  </div>

                  <p className="Qcode-button" onClick={addQcode}>
                    +Add Qcode
                  </p>
                  <div className="Qcode">
                    {qcodes.map((qcode, index) => (
                      <div key={index} className="Qcode-fields row">
                        {/* <div className="feild_row"> */}
                        <label for="cars" className="qcodefield">
                          Status
                        </label>
                        <select id="cars" className="input">
                          <option value="active" selected>
                            active
                          </option>
                          <option value="inactive">inactive</option>
                        </select>
                        <label className="qcodefield ">Qcode Points</label>
                        <input
                          type="number"
                          value={qcode.points}
                          onChange={(e) =>
                            handleInputChange(index, e, "points")
                          }
                          placeholder="Points"
                          className="inputfield input"
                        />
                        {/* </div> */}

                        <label className="qcodefield">Expiry Date</label>
                        <input
                          type="date"
                          onChange={(e) =>
                            handleInputChange(index, e, "expiryDate")
                          }
                          placeholder="Expiry Date"
                          className="inputfield input "
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="footer"
                    onClick={() => {
                      setVisible(false);
                    }}
                  >
                    Close
                  </button>
                  <button className="footer-button" onClick={onPressQcode}>
                    Create Card
                  </button>
                </div>
              </div>
            </div>
          </>
          {PRFeedData.length < 10 && (
            <Fab
              style={{ marginBottom: "20px" }}
              color="secondary"
              component="span"
              aria-label="add"
              variant="extended"
              onClick={() => {
                if (PRFeedData.length == 10) {
                  alert("Maximum of 10 PR Feed data only");
                } else {
                  let obj = {
                    id: PRFeedData.length + 1,
                    subject_line: "",
                    feed_show_status: true,
                    input_text: "",
                    picture_1: {},
                    picture_2: {},
                    picture_1Url: "",
                    picture_2Url: "",
                    post_start_date: currentDate,
                    post_end_date: currentDate,
                  };
                  setModalData(obj);
                  setShowModal(true);
                }
              }}
            >
              <AddIcon />
              {structure.headerName}
            </Fab>
          )}

          <Modal
            onClose={() => {
              setBonuskarteModal(false);
            }}
            open={bonuskarteModal}
            style={{
              display: "flex",
              p: 1,
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              overflow: "scroll",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                //marginTop:'10px',
                top: "0px",
                //paddingTop:'150px',
                //width:'750px',
                padding: "10px",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: (theme) => theme.shadows[5],
                p: 4,
                //display: "flex",
                alignItems: "center",
              }}
            >
              <h2 style={{ textAlign: "center" }}>BonusKarte 2</h2>
              <TextField
                className={`${id}_${structure.field}`}
                //id={}
                variant="outlined"
                type={"text"}
                label={"PR Text"}
                sx={{
                  marginBottom: "0.5em",
                  width: "30em",
                  display: checkField(structure.field, item),
                }}
                value={modalData.subject_line}
                onChange={(e) => {
                  const temp = { ...modalData };
                  temp.subject_line = e.currentTarget.value;
                  setModalData(temp);
                }}
                disabled={false}
              />
              <br />
              <FormControlLabel
                control={
                  <Switch
                    className={`${id}_${structure.field}`}
                    id={modalData.feed_show_status ? "1" : "0"}
                    checked={modalData.feed_show_status ? true : false}
                    onChange={(e) => {
                      const temp = { ...modalData };
                      temp.feed_show_status = e.currentTarget.checked;
                      setModalData(temp);
                    }}
                  />
                }
                label={"Post in Feed"}
                disabled={structure.isDisabled ? true : false}
                sx={{
                  marginBottom: "1em",
                  width: "30em",
                }}
              />
              {modalData.like_count != undefined && (
                <Stack direction="row" spacing={2}>
                  <ThumbUp color="error" />
                  <div style={{ marginLeft: "10px", marginBottom: "5px" }}>
                    Total liked:{modalData.like_count}
                  </div>
                </Stack>
              )}
              {modalData.wishlist_count != undefined && (
                <Stack direction="row" spacing={2}>
                  <ShoppingCart color="error" />
                  <div style={{ marginLeft: "10px" }}>
                    {" "}
                    Total Wistlist: {modalData.wishlist_count}
                  </div>
                </Stack>
              )}
              <br />
              <TextareaAutosize
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                name={`${id}_${structure.field}`}
                type={"multiText"}
                label={"Details..."}
                placeholder={"Details..."}
                autoComplete="false"
                maxRows={10}
                minRows={5}
                variant="outlined"
                aria-label="maximum height"
                style={{
                  marginBottom: "1em",
                  width: "27em",
                  fontSize: "18px",
                  padding: "0.5em",
                  display: checkField(structure.field, item),
                }}
                value={modalData.input_text}
                onChange={(e) => {
                  const temp = { ...modalData };
                  temp.input_text = e.currentTarget.value;
                  setModalData(temp);
                }}
                disabled={false}
              />
              <br />
              <div
                className={item.picture_1Url}
                style={{ marginBottom: "1em" }}
              >
                <Stack direction="row" spacing={2}>
                  <input
                    //style={{ display: "none" }}
                    type="file"
                    onChange={(e) => {
                      let size = e.target.files[0].size;

                      if (size > 10485760) {
                        alert("Please Upload files under 10 mb.");
                        return false;
                      }
                      const temp = { ...modalData };
                      const tmppath = URL.createObjectURL(e.target.files[0]);
                      temp.picture_1Url = tmppath;
                      temp.picture_1 = e.target.files[0];
                      //temp.picture_1=base64String
                      setModalData(temp);
                    }}
                  />
                  {modalData.picture_1Url &&
                    !modalData.picture_1Url.endsWith("undefined") ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          height: 120,
                          width: 120,
                        },
                      }}
                    >
                      <Paper elevation={3} sx={{ position: "relative" }}>
                        <CancelIcon
                          onClick={(e) => {
                            const temp = { ...modalData };
                            temp.picture_1Url = "";
                            temp.picture_1 = {};
                            setModalData(temp);
                          }}
                          sx={{
                            right: "-20px",
                            top: "-18px",
                            position: "absolute",
                          }}
                        />
                        {modalData.picture_1Url !== null ||
                          modalData.picture_1Url !== "" ||
                          !modalData.picture_1Url.endsWith("undefined") ? (
                          <img
                            style={{ borderRadius: "2px" }}
                            height="120"
                            width="120"
                            src={modalData.picture_1Url}
                            alt={"noImage"}
                          />
                        ) : (
                          <></>
                        )}
                      </Paper>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Stack>
              </div>
              <div
                className={item.picture_2Url}
                style={{ marginBottom: "1em" }}
              >
                <Stack direction="row" spacing={2}>
                  <input
                    //style={{ display: "none" }}
                    type="file"
                    onChange={(e) => {
                      let size = e.target.files[0].size;

                      if (size > 10485760) {
                        alert("Please Upload files under 10 mb.");
                        return false;
                      }
                      const temp = { ...modalData };
                      const file = e.target.files[0];
                      //     const reader = new FileReader();
                      //   reader.onload = () => {
                      //   const base64 = reader.result;
                      //   temp.picture_2=base64
                      //     };

                      //  reader.readAsDataURL(file);
                      var tmppath = URL.createObjectURL(e.target.files[0]);
                      temp.picture_2Url = tmppath;
                      temp.picture_2 = e.target.files[0];

                      setModalData(temp);
                    }}
                  />
                  {modalData.picture_2Url &&
                    !modalData.picture_2Url.endsWith("undefined") ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          height: 120,
                          width: 120,
                        },
                      }}
                    >
                      <Paper elevation={3} sx={{ position: "relative" }}>
                        <CancelIcon
                          onClick={(e) => {
                            const temp = { ...modalData };
                            temp.picture_2Url = "";
                            temp.picture_2 = {};
                            setModalData(temp);
                          }}
                          sx={{
                            right: "-20px",
                            top: "-18px",
                            position: "absolute",
                          }}
                        />
                        {modalData.picture_2Url !== null ||
                          modalData.picture_2Url !== "" ||
                          modalData.picture_2Url !== undefined ? (
                          <img
                            style={{ borderRadius: "2px" }}
                            height="120"
                            width="120"
                            src={modalData.picture_2Url}
                          />
                        ) : (
                          <></>
                        )}
                      </Paper>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Stack>
              </div>
              <br />
              <TextField
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                name={`${id}_${structure.field}`}
                type={"date"}
                label={"Post start day"}
                autoComplete="false"
                variant="outlined"
                sx={{
                  marginBottom: "2em",
                  width: "30em",
                  display: structure.hideInput ? "none" : "",
                }}
                value={modalData.post_start_date}
                onChange={(e) => {
                  const temp = { ...modalData };
                  temp.post_start_date = e.currentTarget.value;
                  setModalData(temp);
                }}
                disabled={false}
              />
              <br />
              <TextField
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                name={`${id}_${structure.field}`}
                type={"date"}
                label={"Post end day"}
                autoComplete="false"
                variant="outlined"
                sx={{
                  marginBottom: "2em",
                  width: "30em",
                  display: structure.hideInput ? "none" : "",
                }}
                value={modalData.post_end_date}
                onChange={(e) => {
                  // let key = structure.field
                  // setItem({ ...item, [key]: e.currentTarget.value })
                  // values = { ...values, [key]: e.currentTarget.value }
                  const temp = { ...modalData };
                  temp.post_end_date = e.currentTarget.value;
                  setModalData(temp);
                }}
                disabled={false}
              />
              <br />

              {modalData.index != undefined ? (
                <>
                  <Fab
                    style={{ marginBottom: "20px", marginRight: "20px" }}
                    color="secondary"
                    component="span"
                    aria-label="add"
                    variant="extended"
                    onClick={() => {
                      editFetchFunc();
                      // console.log("modal Data=====>",modalData)
                      // if(modalData.subject_line=='' || modalData.input_text=='' ||modalData.picture_1Url==''|| modalData.picture_2Url==''){
                      //   alert(`Please provide all the field`)
                      //   return
                      // }else if(modalData.post_start_date >= modalData.post_end_date){
                      //   alert("Please Provide valid Date range")
                      //   return
                      // }else{
                      //   let key = structure.field
                      //      const tempArr=[...PRFeedData]
                      //      tempArr[modalData.index]=modalData

                      //      setPRFeedData(tempArr)
                      //       setItem({ ...item, [key]: tempArr })
                      //      values = { ...values, [key]:tempArr }
                      //      setShowModal(false)
                      //      console.log("=======values========>",values)
                      // }
                    }}
                  >
                    {"Edit PR Field"}
                  </Fab>
                  <Fab
                    style={{ marginBottom: "20px" }}
                    color="error"
                    component="span"
                    aria-label="add"
                    variant="extended"
                    onClick={() => {
                      cancelPRFeedPostFunc(modalData);
                    }}
                  >
                    <DeleteIcon /> {"Delete PR"}
                  </Fab>
                </>
              ) : (
                <>
                  <Fab
                    style={{ marginBottom: "20px", marginRight: "20px" }}
                    color="secondary"
                    component="span"
                    aria-label="add"
                    variant="extended"
                    onClick={() => {
                      addFetchFunc();
                    }}
                  >
                    <AddIcon />
                    {"Add PR Field"}
                  </Fab>
                  <Fab
                    style={{ marginBottom: "20px" }}
                    color="warning"
                    component="span"
                    aria-label="add"
                    variant="extended"
                    onClick={() => {
                      setBonuskarteModal(false);
                    }}
                  >
                    {"Cancel"}
                  </Fab>
                </>
              )}
            </Box>
          </Modal>
          <Modal
            onClose={() => {
              setShowModal(false);
            }}
            open={showModal}
            style={{
              display: "flex",
              p: 1,
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              overflow: "scroll",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                //marginTop:'10px',
                top: "0px",
                //paddingTop:'150px',
                //width:'750px',
                padding: "10px",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: (theme) => theme.shadows[5],
                p: 4,
                //display: "flex",
                alignItems: "center",
              }}
            >
              <h2 style={{ textAlign: "center" }}>
                PR presentation for Welcome page
              </h2>
              <TextField
                className={`${id}_${structure.field}`}
                //id={}
                variant="outlined"
                type={"text"}
                label={"PR Text"}
                sx={{
                  marginBottom: "0.5em",
                  width: "30em",
                  display: checkField(structure.field, item),
                }}
                value={modalData.subject_line}
                onChange={(e) => {
                  const temp = { ...modalData };
                  temp.subject_line = e.currentTarget.value;
                  setModalData(temp);
                }}
                disabled={false}
              />
              <br />
              <FormControlLabel
                control={
                  <Switch
                    className={`${id}_${structure.field}`}
                    id={modalData.feed_show_status ? "1" : "0"}
                    checked={modalData.feed_show_status ? true : false}
                    onChange={(e) => {
                      const temp = { ...modalData };
                      temp.feed_show_status = e.currentTarget.checked;
                      setModalData(temp);
                    }}
                  />
                }
                label={"Post in Feed"}
                disabled={structure.isDisabled ? true : false}
                sx={{
                  marginBottom: "1em",
                  width: "30em",
                }}
              />
              {modalData.like_count != undefined && (
                <Stack direction="row" spacing={2}>
                  <ThumbUp color="error" />
                  <div style={{ marginLeft: "10px", marginBottom: "5px" }}>
                    Total liked:{modalData.like_count}
                  </div>
                </Stack>
              )}
              {modalData.wishlist_count != undefined && (
                <Stack direction="row" spacing={2}>
                  <ShoppingCart color="error" />
                  <div style={{ marginLeft: "10px" }}>
                    {" "}
                    Total Wistlist: {modalData.wishlist_count}
                  </div>
                </Stack>
              )}
              <br />
              <TextareaAutosize
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                name={`${id}_${structure.field}`}
                type={"multiText"}
                label={"Details..."}
                placeholder={"Details..."}
                autoComplete="false"
                maxRows={10}
                minRows={5}
                variant="outlined"
                aria-label="maximum height"
                style={{
                  marginBottom: "1em",
                  width: "27em",
                  fontSize: "18px",
                  padding: "0.5em",
                  display: checkField(structure.field, item),
                }}
                value={modalData.input_text}
                onChange={(e) => {
                  const temp = { ...modalData };
                  temp.input_text = e.currentTarget.value;
                  setModalData(temp);
                }}
                disabled={false}
              />
              <br />
              <div
                className={item.picture_1Url}
                style={{ marginBottom: "1em" }}
              >
                <Stack direction="row" spacing={2}>
                  <input
                    //style={{ display: "none" }}
                    type="file"
                    onChange={(e) => {
                      let size = e.target.files[0].size;
                      //let file = document.getElementById(e.currentTarget.id)

                      if (size > 10485760) {
                        alert("Please Upload files under 10 mb.");
                        return false;
                      }
                      const temp = { ...modalData };
                      //const file = e.target.files[0];
                      //     const reader = new FileReader();
                      //      reader.onload = () => {
                      //    const srcData = reader.result;
                      //     //console.log('base64:', srcData)
                      //     temp.picture_1=srcData
                      //   };

                      //  reader.readAsDataURL(file);
                      const tmppath = URL.createObjectURL(e.target.files[0]);
                      temp.picture_1Url = tmppath;
                      temp.picture_1 = e.target.files[0];
                      //temp.picture_1=base64String
                      setModalData(temp);
                    }}
                  />
                  {modalData.picture_1Url &&
                    !modalData.picture_1Url.endsWith("undefined") ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          height: 120,
                          width: 120,
                        },
                      }}
                    >
                      <Paper elevation={3} sx={{ position: "relative" }}>
                        <CancelIcon
                          onClick={(e) => {
                            const temp = { ...modalData };
                            temp.picture_1Url = "";
                            temp.picture_1 = {};
                            setModalData(temp);
                          }}
                          sx={{
                            right: "-20px",
                            top: "-18px",
                            position: "absolute",
                          }}
                        />
                        {modalData.picture_1Url !== null ||
                          modalData.picture_1Url !== "" ||
                          !modalData.picture_1Url.endsWith("undefined") ? (
                          <img
                            style={{ borderRadius: "2px" }}
                            height="120"
                            width="120"
                            src={modalData.picture_1Url}
                            alt={"noImage"}
                          />
                        ) : (
                          <></>
                        )}
                      </Paper>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Stack>
              </div>
              <div
                className={item.picture_2Url}
                style={{ marginBottom: "1em" }}
              >
                <Stack direction="row" spacing={2}>
                  <input
                    //style={{ display: "none" }}
                    type="file"
                    onChange={(e) => {
                      let size = e.target.files[0].size;
                      //let file = document.getElementById(e.currentTarget.id)

                      if (size > 10485760) {
                        alert("Please Upload files under 10 mb.");
                        return false;
                      }
                      const temp = { ...modalData };
                      const file = e.target.files[0];
                      //     const reader = new FileReader();
                      //   reader.onload = () => {
                      //   const base64 = reader.result;
                      //   temp.picture_2=base64
                      //     };

                      //  reader.readAsDataURL(file);
                      var tmppath = URL.createObjectURL(e.target.files[0]);
                      temp.picture_2Url = tmppath;
                      temp.picture_2 = e.target.files[0];

                      setModalData(temp);
                    }}
                  />
                  {modalData.picture_2Url &&
                    !modalData.picture_2Url.endsWith("undefined") ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          height: 120,
                          width: 120,
                        },
                      }}
                    >
                      <Paper elevation={3} sx={{ position: "relative" }}>
                        <CancelIcon
                          onClick={(e) => {
                            const temp = { ...modalData };
                            temp.picture_2Url = "";
                            temp.picture_2 = {};
                            setModalData(temp);
                          }}
                          sx={{
                            right: "-20px",
                            top: "-18px",
                            position: "absolute",
                          }}
                        />
                        {modalData.picture_2Url !== null ||
                          modalData.picture_2Url !== "" ||
                          modalData.picture_2Url !== undefined ? (
                          <img
                            style={{ borderRadius: "2px" }}
                            height="120"
                            width="120"
                            src={modalData.picture_2Url}
                          />
                        ) : (
                          <></>
                        )}
                      </Paper>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Stack>
              </div>
              <br />
              <TextField
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                name={`${id}_${structure.field}`}
                type={"date"}
                label={"Post start day"}
                autoComplete="false"
                variant="outlined"
                sx={{
                  marginBottom: "2em",
                  width: "30em",
                  display: structure.hideInput ? "none" : "",
                }}
                value={modalData.post_start_date}
                onChange={(e) => {
                  const temp = { ...modalData };
                  temp.post_start_date = e.currentTarget.value;
                  setModalData(temp);
                }}
                disabled={false}
              />
              <br />
              <TextField
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                name={`${id}_${structure.field}`}
                type={"date"}
                label={"Post end day"}
                autoComplete="false"
                variant="outlined"
                sx={{
                  marginBottom: "2em",
                  width: "30em",
                  display: structure.hideInput ? "none" : "",
                }}
                value={modalData.post_end_date}
                onChange={(e) => {
                  // let key = structure.field
                  // setItem({ ...item, [key]: e.currentTarget.value })
                  // values = { ...values, [key]: e.currentTarget.value }
                  const temp = { ...modalData };
                  temp.post_end_date = e.currentTarget.value;
                  setModalData(temp);
                }}
                disabled={false}
              />
              <br />

              {modalData.index != undefined ? (
                <>
                  <Fab
                    style={{ marginBottom: "20px", marginRight: "20px" }}
                    color="secondary"
                    component="span"
                    aria-label="add"
                    variant="extended"
                    onClick={() => {
                      editFetchFunc();
                      // console.log("modal Data=====>",modalData)
                      // if(modalData.subject_line=='' || modalData.input_text=='' ||modalData.picture_1Url==''|| modalData.picture_2Url==''){
                      //   alert(`Please provide all the field`)
                      //   return
                      // }else if(modalData.post_start_date >= modalData.post_end_date){
                      //   alert("Please Provide valid Date range")
                      //   return
                      // }else{
                      //   let key = structure.field
                      //      const tempArr=[...PRFeedData]
                      //      tempArr[modalData.index]=modalData

                      //      setPRFeedData(tempArr)
                      //       setItem({ ...item, [key]: tempArr })
                      //      values = { ...values, [key]:tempArr }
                      //      setShowModal(false)
                      //      console.log("=======values========>",values)
                      // }
                    }}
                  >
                    {"Edit PR Field"}
                  </Fab>
                  <Fab
                    style={{ marginBottom: "20px" }}
                    color="error"
                    component="span"
                    aria-label="add"
                    variant="extended"
                    onClick={() => {
                      cancelPRFeedPostFunc(modalData);
                    }}
                  >
                    <DeleteIcon /> {"Delete PR"}
                  </Fab>
                </>
              ) : (
                <>
                  <Fab
                    style={{ marginBottom: "20px", marginRight: "20px" }}
                    color="secondary"
                    component="span"
                    aria-label="add"
                    variant="extended"
                    onClick={() => {
                      addFetchFunc();
                    }}
                  >
                    <AddIcon />
                    {"Add PR Field"}
                  </Fab>
                  <Fab
                    style={{ marginBottom: "20px" }}
                    color="warning"
                    component="span"
                    aria-label="add"
                    variant="extended"
                    onClick={() => {
                      setBonuskarteModal(false);
                    }}
                  >
                    {"Cancel"}
                  </Fab>
                </>
              )}
            </Box>
          </Modal>
        </>
      );
    case "totalLiked":
      return (
        <div
          style={{
            marginRight: "20px",
            alignItems: "center",
            display: "flex",
            height: "40px",
            alignSelf: "center",
          }}
        >
          <ThumbUp color="primary" />
          <h4 style={{ marginLeft: "5px" }}>
            Total liked : {item[structure.field]}
          </h4>
        </div>
      );
    case "totalWishlist":
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            height: "40px",
            alignSelf: "center",
            marginBottom: "20px",
          }}
        >
          <ShoppingCart color="primary" />
          <h4 style={{ marginLeft: "5px" }}>
            Total Wishlists : {item[structure.field]}
          </h4>
        </div>
      );

    default:
      return <></>;
  }
};

const getData = (props, category, id) => {
  switch (category) {
    case "Admin":
      props.getOneUser(id);
      break;
    case "Customer":
      props.getOneCustomer(id);
      break;
    case "Provider":
      props.getOneProvider(id);
      break;
    case "Voucher":
      props.getOneVoucher(id);
      break;
    case "Product":
      props.getOneProduct(id);
      break;
    case "Category":
      props.getOnecategory(id);
      break;
    default:
      break;
  }
};

const handleSubmit = (props, category, id) => {
  console.log(
    "------------------->",
    props.providers.data[0].deliveryCost,
    props.providers.data[0].minOrderCost
  );

  var formData = new FormData();
  let keys = Object.keys(values);

  if (
    props.providers.data[0].deliveryCost == undefined ||
    props.providers.data[0].deliveryCost == null ||
    props.providers.data[0].deliveryCost == ""
  ) {
    if (
      values.deliveryCost == undefined ||
      values.deliveryCost == null ||
      values.deliveryCost == ""
    ) {
      alert("Please provide Delivery cost");
      return;
    }
  }
  if (values.deliveryCost == "") {
    alert("Please provide Delivery cost");
    return;
  }

  if (
    props.providers.data[0].minOrderCost == undefined ||
    props.providers.data[0].minOrderCost == null ||
    props.providers.data[0].minOrderCost == ""
  ) {
    if (
      values.minOrderCost == undefined ||
      values.minOrderCost == null ||
      values.minOrderCost == ""
    ) {
      alert("Please provide Minimum Order cost");
      return;
    }
  }
  if (values.minOrderCost == "") {
    alert("Please provide Minimum Order cost");
    return;
  }

  if (values.pdfDocument) {
    if (
      values.DocumentStartDay == undefined ||
      values.DocumentStartDay == null ||
      values.DocumentStartDay == ""
    ) {
      alert("Please provide Document Start Date");
      return;
    }
    if (
      values.DocumentEndDay == undefined ||
      values.DocumentEndDay == null ||
      values.DocumentEndDay == ""
    ) {
      alert("Please provide Document End Date");
      return;
    }
    if (
      values.DocumentStartDay != undefined &&
      values.DocumentEndDay != undefined
    ) {
      if (values.DocumentStartDay >= values.DocumentEndDay) {
        alert("Please provide Document Valid Date Range");
        return;
      }
    }
  }
  if (values.DocumentEndDay !== undefined) {
    if (
      values.DocumentStartDay &&
      values.DocumentStartDay >= values.DocumentEndDay
    ) {
      alert("Please provide Document Valid Date Range");
      return;
    } else if (
      props.providers.data[0].DocumentStartDay &&
      props.providers.data[0].DocumentStartDay >= values.DocumentEndDay
    ) {
      alert("Please provide Document Valid Date Range");
      return;
    }
  }

  keys.forEach((e) => {
    if (e == "PRFeedData") {
      if (values[e].length == 1) {
        formData.append("PRFeedData", [JSON.stringify(values[e])]);
      } else {
        values[e].map((item) =>
          formData.append("PRFeedData", JSON.stringify(item))
        );
      }
    } else {
      formData.append(e, values[e]);
    }
  });

  // if(fields.includes("email")){

  //   if(values.email){
  //     if(values.email === " " || values.email === null){
  //       alert("Please provide your email id.")
  //     }

  //     const validateEmail = () => {
  //       var regexp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  //       return regexp.test(values.email.trim());
  //     };

  //     if (!validateEmail()) {
  //       alert("Please enter valid email id.")
  //       return;
  //     }
  //   }else {
  //     alert("Please provide your email.")
  //     return false
  //   }

  // }

  switch (category) {
    case "Admin":
      props.updateUser(formData, { "Content-Type": "multipart/form-data" });
      break;
    case "Customer":
      props.updateCustomer(formData, { "Content-Type": "multipart/form-data" });
      break;
    case "Provider":
      props.enableLoder();
      props.updateProvider(formData, { "Content-Type": "multipart/form-data" });
      break;
    case "Voucher":
      props.updateVoucher(formData, { "Content-Type": "multipart/form-data" });
      break;
    case "Product":
      props.updateProduct(formData, { "Content-Type": "multipart/form-data" });
      break;

    default:
      break;
  }
};

const UpdateData = (props) => {
  const location = useLocation();

  const [isFetching, setFetching] = useState(false);
  const [providerId, setProviderId]= useState('')

  const navigate = useNavigate();

  let deleteProvider = (pId)=>{
    setFetching(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/providers/deleteOneUser`, {id: pId}) // providerId).
      .then((response) => {
        setFetching(false);
        const goBack = () => {
          navigate('/providers'); 
        };
        goBack();
      })
      .catch((error) => {
        console.log("Error fetching report data ", error);
      });
  }

  useEffect(() => {
    values = {};
    fields = [];
    structures[location.state.fieldKey].forEach((i) => {
      if (!i.hideInput) fields.push(i.field);
    });
    let id = location.state.id;
    setProviderId(location.state.id)

    $(document).on("change", `#${id}_paypalMode`, (e) => {
      // console.log(e.target.checked)
      let wd = $(`#${id}_providerName`).width();
      if (e.target.checked) {
        $(`.${id}_paypalClientId`).css("display", "block");
        $(`#${id}_paypalClientId`).width(wd);
        $(`.${id}_paypalClientSecret`).css("display", "block");
        $(`#${id}_paypalClientSecret`).width(wd);
        // notRequired.Provider.pop("paypalClientId")
      } else {
        $(`.${id}_paypalClientId`).css("display", "none");
        $(`.${id}_paypalClientSecret`).css("display", "none");
        // notRequired.Provider.push("paypalClientId")
      }
    });

    getData(props, location.state.pageTitle, location.state.id);
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        noWrap
        component="div"
        sx={{ marginTop: "1em", marginBottom: "1.5em" }}
      >
        {`Update ${location.state.pageTitle}`}
        {
          location.state.pageTitle == "Provider" && <DeleteIcon
            style={{ marginRight: "5px", marginLeft: "5px", color: 'red' }}
            onClick={() => {
              console.log("1111111111111", providerId)
              deleteProvider(providerId)
            }}
          />
        }
      </Typography>
      {structures[location.state.fieldKey].map((item, index) => (
        <div key={index}>
          <GetField
            structure={item}
            id={location.state.id}
            data={props[location.state.dataKey].data[0] || {}}
          />
        </div>
      ))}

      <Loder />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetching}
        onClick={() => { }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Button
        variant="contained"
        color="success"
        size="large"
        sx={{
          marginBottom: "2em",
        }}
        onClick={(e) => {
          handleSubmit(props, location.state.pageTitle, location.state.id);
        }}
      >
        Update
      </Button>
    </>
  );
};

export default connect(mapStateToProps, mapDispachToProps)(UpdateData);
