const columns = [
    { 
        field: 'id',
        headerName: 'ID',
        inputType: 'text',
        width: 180,
        isDisabled: false,
        hideInput: true
    },
    {
        field: 'Name',
        headerName: 'Category name',
        sortable: true,
        inputType: 'text',
        width: 180,
        isDisabled: false
    },
    {
        field: 'type',
        headerName: 'Category type',
        sortable: true,
        inputType: 'select1',
        width: 180,
        isDisabled: false,
        data:['Product','Community','Provider']
    }
];

export default columns;